import * as _fs2 from "@nodelib/fs.stat";

var _fs = "default" in _fs2 ? _fs2.default : _fs2;

import * as _runParallel2 from "run-parallel";

var _runParallel = "default" in _runParallel2 ? _runParallel2.default : _runParallel2;

import _constants from "../constants";
import _utils from "../utils";
import _common from "./common";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.readdir = exports.readdirWithFileTypes = exports.read = void 0;
const fsStat = _fs;
const rpl = _runParallel;
const constants_1 = _constants;
const utils = _utils;
const common = _common;

function read(directory, settings, callback) {
  if (!settings.stats && constants_1.IS_SUPPORT_READDIR_WITH_FILE_TYPES) {
    readdirWithFileTypes(directory, settings, callback);
    return;
  }

  readdir(directory, settings, callback);
}

exports.read = read;

function readdirWithFileTypes(directory, settings, callback) {
  settings.fs.readdir(directory, {
    withFileTypes: true
  }, (readdirError, dirents) => {
    if (readdirError !== null) {
      callFailureCallback(callback, readdirError);
      return;
    }

    const entries = dirents.map(dirent => ({
      dirent,
      name: dirent.name,
      path: common.joinPathSegments(directory, dirent.name, settings.pathSegmentSeparator)
    }));

    if (!settings.followSymbolicLinks) {
      callSuccessCallback(callback, entries);
      return;
    }

    const tasks = entries.map(entry => makeRplTaskEntry(entry, settings));
    rpl(tasks, (rplError, rplEntries) => {
      if (rplError !== null) {
        callFailureCallback(callback, rplError);
        return;
      }

      callSuccessCallback(callback, rplEntries);
    });
  });
}

exports.readdirWithFileTypes = readdirWithFileTypes;

function makeRplTaskEntry(entry, settings) {
  return done => {
    if (!entry.dirent.isSymbolicLink()) {
      done(null, entry);
      return;
    }

    settings.fs.stat(entry.path, (statError, stats) => {
      if (statError !== null) {
        if (settings.throwErrorOnBrokenSymbolicLink) {
          done(statError);
          return;
        }

        done(null, entry);
        return;
      }

      entry.dirent = utils.fs.createDirentFromStats(entry.name, stats);
      done(null, entry);
    });
  };
}

function readdir(directory, settings, callback) {
  settings.fs.readdir(directory, (readdirError, names) => {
    if (readdirError !== null) {
      callFailureCallback(callback, readdirError);
      return;
    }

    const tasks = names.map(name => {
      const path = common.joinPathSegments(directory, name, settings.pathSegmentSeparator);
      return done => {
        fsStat.stat(path, settings.fsStatSettings, (error, stats) => {
          if (error !== null) {
            done(error);
            return;
          }

          const entry = {
            name,
            path,
            dirent: utils.fs.createDirentFromStats(name, stats)
          };

          if (settings.stats) {
            entry.stats = stats;
          }

          done(null, entry);
        });
      };
    });
    rpl(tasks, (rplError, entries) => {
      if (rplError !== null) {
        callFailureCallback(callback, rplError);
        return;
      }

      callSuccessCallback(callback, entries);
    });
  });
}

exports.readdir = readdir;

function callFailureCallback(callback, error) {
  callback(error);
}

function callSuccessCallback(callback, result) {
  callback(null, result);
}

export default exports;