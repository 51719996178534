import _async from "./providers/async";
import _sync from "./providers/sync";
import _settings from "./settings";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Settings = exports.scandirSync = exports.scandir = void 0;
const async = _async;
const sync = _sync;
const settings_1 = _settings;
exports.Settings = settings_1.default;

function scandir(path, optionsOrSettingsOrCallback, callback) {
  if (typeof optionsOrSettingsOrCallback === "function") {
    async.read(path, getSettings(), optionsOrSettingsOrCallback);
    return;
  }

  async.read(path, getSettings(optionsOrSettingsOrCallback), callback);
}

exports.scandir = scandir;

function scandirSync(path, optionsOrSettings) {
  const settings = getSettings(optionsOrSettings);
  return sync.read(path, settings);
}

exports.scandirSync = scandirSync;

function getSettings(settingsOrOptions = {}) {
  if (settingsOrOptions instanceof settings_1.default) {
    return settingsOrOptions;
  }

  return new settings_1.default(settingsOrOptions);
}

export default exports;
export const __esModule = exports.__esModule,
      Settings = exports.Settings;
const _scandirSync = exports.scandirSync,
      _scandir = exports.scandir;
export { _scandirSync as scandirSync, _scandir as scandir };