var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.joinPathSegments = void 0;

function joinPathSegments(a, b, separator) {
  /**
   * The correct handling of cases when the first segment is a root (`/`, `C:/`) or UNC path (`//?/C:/`).
   */
  if (a.endsWith(separator)) {
    return a + b;
  }

  return a + separator + b;
}

exports.joinPathSegments = joinPathSegments;
export default exports;