import * as _fs2 from "@nodelib/fs.stat";

var _fs = "default" in _fs2 ? _fs2.default : _fs2;

import _constants from "../constants";
import _utils from "../utils";
import _common from "./common";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.readdir = exports.readdirWithFileTypes = exports.read = void 0;
const fsStat = _fs;
const constants_1 = _constants;
const utils = _utils;
const common = _common;

function read(directory, settings) {
  if (!settings.stats && constants_1.IS_SUPPORT_READDIR_WITH_FILE_TYPES) {
    return readdirWithFileTypes(directory, settings);
  }

  return readdir(directory, settings);
}

exports.read = read;

function readdirWithFileTypes(directory, settings) {
  const dirents = settings.fs.readdirSync(directory, {
    withFileTypes: true
  });
  return dirents.map(dirent => {
    const entry = {
      dirent,
      name: dirent.name,
      path: common.joinPathSegments(directory, dirent.name, settings.pathSegmentSeparator)
    };

    if (entry.dirent.isSymbolicLink() && settings.followSymbolicLinks) {
      try {
        const stats = settings.fs.statSync(entry.path);
        entry.dirent = utils.fs.createDirentFromStats(entry.name, stats);
      } catch (error) {
        if (settings.throwErrorOnBrokenSymbolicLink) {
          throw error;
        }
      }
    }

    return entry;
  });
}

exports.readdirWithFileTypes = readdirWithFileTypes;

function readdir(directory, settings) {
  const names = settings.fs.readdirSync(directory);
  return names.map(name => {
    const entryPath = common.joinPathSegments(directory, name, settings.pathSegmentSeparator);
    const stats = fsStat.statSync(entryPath, settings.fsStatSettings);
    const entry = {
      name,
      path: entryPath,
      dirent: utils.fs.createDirentFromStats(name, stats)
    };

    if (settings.stats) {
      entry.stats = stats;
    }

    return entry;
  });
}

exports.readdir = readdir;
export default exports;